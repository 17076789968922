<template>
  <v-app id="login_module" class="login-overflow">
    <v-app-bar id="login_header" height="60" :width="'100%'" app flat class="w-100" color="#ffffff">
      <img id="login_header_img" src="../assets/images/abml_logo.jpg" alt=""  class="py-1" style="padding-left: 5%;padding-right: 20px" /> 
      <div style="background-color: #c7222a; padding-right: 60px;height: 60px" class="d-flex align-center justify-end w-100 rounded-bl-lg" >
      <a href="https://stocksandsecurities.adityabirlacapital.com/eKYCcampaign/ekyccampaign_registernew.aspx?utm_source=tradeliteweb" v-if="$store.state.windowWidth > 490" target="_blank" class="px-2 py-1 rounded-xxl text-decoration-none fsize14 activeColor" style="border: solid 1px" rel="noopener noreferrer">Refer & Earn</a>
      <a href="https://alphaekyc.adityabirlamoney.com/?utm_source=TradeLite&utm_medium=headerbutton" target="_blank" v-if="$store.state.windowWidth > 490" rel="noopener noreferrer" class="ml-4 px-2 py-1 rounded-xxl text-decoration-none fsize14 activeColor" style="border: solid 1px " >Open an Account</a>
      </div>
    </v-app-bar>
    <div style="color: #c7222a;position: fixed; top: 60px; padding: 10px 0px 10px 5%; border-bottom: solid 1px #ccc;border-top: solid 1px #ccc; background-color: #ffffff;z-index: 9;" class=" d-flex align-center w-100 font-weight-600">
      Aditya Birla Money Limited
    </div>
    <v-layout row wrap align-center justify-center class="opansans-font ma-0"
      style="height: calc(100vh - 60px) !important; margin-top: 60px !important; width: 100vw">
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center text-center id="imgPart"
        v-if="$store.state.windowWidth > 1023">
        <img id="login_dashboard_img" :src="$store.state.dashboardLogo" alt="LoginIcon" Lazy="load" decode="async"
          class="w-50" />
      </v-flex>
      <v-flex xs12 sm12 md6 lg6 xl6 align-center justify-center>
        <v-layout row wrap align-center flex-column justify-center>
          <div v-if="$store.state.ssoQuery != '' && $store.state.ssoQuery.vendor != 'Bond bulls'"
            class="d-flex flex-column align-center pb-2 text-center w-100" id="login_vendor_name">
            <img id="login_sso_img" v-if="$store.state.ssoQuery.vendor == 'WEALTHDESK'" height="50px" class="pb-2"
              src="../assets/images/wealthdesk.svg" alt="logo" />
            Login with {{ $store.state.ssoQuery.vendor }}
          </div>
          <div class="rounded custom-Card pa-6 w-344" id="login_module_card">
            <div :class="
              this.whichStep == 'authorize'
                ? 'Img-div text-center'
                : 'Img-div'
            " v-if="
  this.whichStep == 'userId' ||
  this.whichStep == 'Forget' ||
  this.whichStep == 'Unblock' ||
  this.whichStep == 'authorize' ||
  this.whichStep == 'createMpin'
">
              <img id="login_module_img" :src="$store.state.loginLogo" class="h-40" alt="Logo" Lazy="load"
                decode="async" />
            </div>
            <!-- UserId div Start -->
            <div v-if="this.whichStep == 'userId'">
              <div class="fsize20 primaryColor font-weight-regular mb-2" id="login_welcome_msg">
                Welcome to {{ $store.state.projectName }}
              </div>
              <div class="secondColor fsize14 mb-4" id="login_welcome_info">
                Sign in to your account with valid credentials
              </div>

              <form action="" @submit.prevent="checkUserId">
                <div class="mb-3">
                  <label class="fsize14 primaryColor pl-2" id="userId_label">User ID</label>
                  <input id="userId_inp" type="text" v-model="userId" @input="
                    $event.target.value = $event.target.value.toUpperCase()
                  " @keypress="errorMessages = null" placeholder="Enter your User ID"
                    class="primaryColor input-field fsize14" maxlength="50" ref="user" autofocus autocomplete="off" />
                  <div class="error-msg" id="userId_err_msg">
                    <span v-if="errorMessages">{{ errorMessages }}</span>
                  </div>
                </div>
                <v-btn id="user_confirm_btn" depressed :color="$store.state.buttonThemeColor" :loading="userIdLoader"
                  :disabled="userIdLoader" @click="checkUserId"
                  class="flat w-100 text-capitalize fsize14 white--text">Next</v-btn>
              </form>
              <div class="
                    mt-2
                    mb-4
                    d-flex
                    justify-space-between
                    fsize12
                    letter-space
                    linkColor
                  ">
                <span @click="checkIsMpinOrPasswordForget('Forget')" id="forgot_pass_action">Forgot Password?</span>
                <span @click="checkIsMpinOrPasswordForget('Unblock')" id="unblock_acc_action">Unblock Account</span>
              </div>
              <div class="d-flex justify-center fsize12 letter-space" id="New_create_acc_div">
                <span class="primaryColor">New to {{ $store.state.projectLink }}?</span>
                <a id="New_create_acc_link" class="linkColor pl-1 text-decoration-none" :href="$store.state.createAccount"
                  target="_blank">Create an account</a>
              </div>
            </div>
            <!-- UserId div End  -->
            <div v-if="this.whichStep == 'M-Pin' || this.whichStep == 'OTP'">
              <div class="Img-avater text-center mb-4">
                <v-avatar color="#D8ECFF" size="90"> </v-avatar>
                <div class="fsize21 mt-4" id="show_user_Id">{{ getUserId }}</div>
                <div id="switch_acc_Id" class="d-flex justify-center fsize10 letter-space linkColor" @click="ChangeUser">
                  Switch Account
                </div>


              </div>
              <form action="" @submit.prevent="checkVaildPassOrMpin">
                <div class="mb-3">
                  <label class="fsize12 primaryColor l-height-24 pl-2" :id="`${this.whichStep}_label`">
                    {{ secondryStep && secondryStep == 'ForgetOtp' && this.whichStep == 'OTP' ? '4 - Digit OTP' :
                      this.whichStep == 'OTP' ? '6 - Digit OTP' : this.whichStep }}</label>
                  <div class="
                        pswborder
                        rounded
                        d-flex
                        align-center
                        justify-space-between
                        w-100
                        h-40
                      ">
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_inp`" ref="mpin"
                        :placeholder="secondryStep && secondryStep == 'ForgetOtp' && this.whichStep == 'OTP' ? '4 - Digit OTP' : this.whichStep == 'OTP' ? 'Enter your 6 - Digit OTP' : `Enter your ${this.whichStep}`"
                        v-model="newPassAndMpin" :type="passwordFieldTypePassOrMpin"
                        :maxlength="secondryStep && secondryStep == 'ForgetOtp' && this.whichStep == 'OTP' ? '4' : this.whichStep == 'M-Pin' || this.whichStep == 'OTP' ? '6' : '50'"
                        autocomplete="off" @keypress="keyPressNumeric($event)" class="
                            w-100
                            border-none
                            h-40
                            outline-none
                            rounded
                            py-2
                            px-4
                            fsize14
                            primaryColor
                          " required />
                    </span>
                    <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypePassOrMpin()">
                      <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="passOrMpinShowOrHide ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                        :color="$store.state.iconBlackColourCode" />
                    </span>
                  </div>
                  <div class="error-msg">
                    <span v-if="passwordAndMpinErrorMessage" :id="`${this.whichStep}_err_msg`">{{
                      passwordAndMpinErrorMessage
                    }}</span>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_next_btn`" depressed :color="$store.state.buttonThemeColor"
                  :loading="passOrMpinLoader" :disabled="passOrMpinLoader" @click="checkVaildPassOrMpin"
                  class="flat w-100 text-capitalize fsize14 white--text">Next</v-btn>
              </form>
              <div class="
                    mt-2
                    mb-4
                    d-flex
                    justify-end
                    fsize12
                    letter-space
                    linkColor
                  " v-if="this.whichStep == 'OTP'">
                <span id="timer_or_resend_label" @click="getTimer == 'Resend OTP' ? resendOtp() : ''">{{ getTimer }}</span>
              </div>
              <div class="
                    mt-2
                    mb-4
                    d-flex
                    justify-space-between
                    fsize12
                    letter-space
                    linkColor
                  " v-else>
                <span @click="checkIsMpinOrPasswordForget('Forget')" id="forgot_pass_action">
                  {{
                    this.whichStep == "Password"
                    ? "Forgot Password"
                    : "Forgot M-Pin?"
                  }}</span>
                <span @click="checkIsMpinOrPasswordForget('Unblock')" id="unblock_acc_action">Unblock Account</span>
              </div>

              <div class="d-flex justify-center fsize12 letter-space" id="New_create_acc_div">
                <span class="primaryColor">New to {{ $store.state.projectLink }}?</span>
                <a id="New_create_acc_link" class="linkColor pl-1 text-decoration-none" :href="$store.state.createAccount"
                  target="_blank">Create an account</a>
              </div>
            </div>
            <div v-if="this.whichStep == 'Password'">
              <div class="Img-avater text-center mb-4">
                <v-avatar color="#D8ECFF" size="90" id="user_avathar"> </v-avatar>
                <div class="fsize21 mt-4" id="show_user_Id">{{ getUserId }}</div>
                <div id="switch_acc_Id" class="d-flex justify-center fsize10 letter-space linkColor" @click="ChangeUser">
                  Switch Account
                </div>
              </div>
              <form action="" @submit.prevent="checkQuickLogin">
                <div class="mb-3">
                  <div>
                    <label class="fsize12 primaryColor l-height-24 pl-2" :id="`${this.whichStep}_label`">
                      {{ this.whichStep }}</label>
                    <div class="
                          pswborder
                          rounded
                          d-flex
                          align-center
                          justify-space-between
                          w-100
                          h-40
                        ">
                      <span class="inputWithImg cursor">
                        <input :id="`${this.whichStep}_inp`" ref="abmlPass" :placeholder="'Enter your ' + this.whichStep"
                          v-model="abmlPassword" :type="abmlPassShowOrHide" maxlength="50" autocomplete="off" class="
                              w-100
                              border-none
                              h-40
                              outline-none
                              rounded
                              py-2
                              px-4
                              fsize14
                              primaryColor
                            " required />
                      </span>
                      <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypeAbmlPass()">
                        <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                          :name="isAbmlPassShow ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                          :color="$store.state.iconBlackColourCode" />
                      </span>
                    </div>
                    <div class="error-msg">
                      <span v-if="abmlPassErrorMessage" :id="`${this.whichStep}_err_msg`">{{
                        abmlPassErrorMessage
                      }}</span>
                      <span v-if="quickLoginErrorMessage">{{ quickLoginErrorMessage }}</span>
                    </div>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_login_btn`" depressed :color="$store.state.buttonThemeColor"
                  :loading="quickLoader" :disabled="quickLoader" @click="checkQuickLogin"
                  class="flat w-100 text-capitalize fsize14 white--text">Login</v-btn>
              </form>
              <div class="
                    mt-2
                    mb-4
                    d-flex
                    justify-space-between
                    fsize12
                    letter-space
                    linkColor
                  ">
                <span @click="checkIsMpinOrPasswordForget('Forget')" id="forgot_pass_action">
                  {{
                    this.whichStep == "Password"
                    ? "Forgot Password"
                    : "Forgot M-Pin?"
                  }}</span>
                <span @click="checkIsMpinOrPasswordForget('Unblock')" id="unblock_acc_action">Unblock Account</span>
              </div>
              <div class="d-flex justify-center fsize12 letter-space" id="New_create_acc_div">
                <span class="primaryColor">New to {{ $store.state.projectLink }}?</span>
                <a id="New_create_acc_link" class="linkColor pl-1 text-decoration-none" :href="$store.state.createAccount"
                  target="_blank">Create an account</a>
              </div>
            </div>

            <div v-if="this.whichStep == '2FA' || this.whichStep == 'createMpin'">
              <div :class="
                this.whichStep == 'createMpin'
                  ? 'fsize20 primaryColor font-weight-regular mb-4'
                  : 'fsize20 primaryColor font-weight-regular mb-2'
              ">
                {{
                  this.whichStep == "2FA" ? "Security Questions" : "Create Mpin"
                }}
              </div>

              <form action="" @keyup.enter="vaidateAnswer">
                <div class="mb-3">
                  <div class="fsize12 secondColor l-height-24 pl-2" :id="`${this.whichStep}_ques1_label`">
                    {{
                      this.whichStep == "2FA"
                      ? this.userQuestions[0]["Ques"]
                      : "New M-Pin"
                    }}
                  </div>
                  <div class="
                        pswborder
                        rounded
                        d-flex
                        align-center
                        justify-space-between
                        w-100
                        h-40
                      ">
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_ques1_inp`" ref="twofa" class="
                            w-100
                            border-none
                            h-40
                            outline-none
                            rounded
                            py-2
                            px-4
                            fsize14
                            primaryColor
                          " placeholder="Enter your Answer" :maxlength="this.whichStep == '2FA' ? '50' : '6'"
                        autocomplete="off" v-model="secQuesOneAndNewMpin" :type="isSecQues1" autofocus
                        @keypress="keyPressNumeric($event)" />
                    </span>
                    <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypeSecQ1()">
                      <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="isSecQues1ShowOrHide ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                        :color="$store.state.iconBlackColourCode" />
                    </span>
                  </div>
                  <div class="error-msg">
                    <span v-if="securityAndCreateMpinErrorMessage1" :id="`${this.whichStep}_ques1_err_msg`">{{
                      securityAndCreateMpinErrorMessage1
                    }}</span>
                  </div>
                  <div class="fsize12 secondColor l-height-24 pl-2" :id="`${this.whichStep}_ques1_label`">
                    {{
                      this.whichStep == "2FA"
                      ? this.userQuestions[1]["Ques"]
                      : "Confirm M-Pin"
                    }}
                  </div>
                  <div class="
                        pswborder
                        rounded
                        d-flex
                        align-center
                        justify-space-between
                        w-100
                        h-40
                      ">
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_ques2_inp`" class="
                            w-100
                            border-none
                            h-40
                            outline-none
                            rounded
                            py-2
                            px-4
                            fsize14
                            primaryColor
                          " placeholder="Enter your Answer" :maxlength="this.whichStep == '2FA' ? '50' : '6'"
                        autocomplete="off" v-model="secQuesTwoAndConMpin" outlined :type="isSecQues2"
                        @keypress="keyPressNumeric($event)" />
                    </span>
                    <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypeSecQ2()">
                      <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="isSecQues2ShowOrHide ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                        :color="$store.state.iconBlackColourCode" />
                    </span>
                  </div>
                  <div class="negativeColor fsize12 h-12" :id="`${this.whichStep}_ques2_err_msg`">
                    <span v-if="this.whichStep == '2FA' && questsErrorMessage != ''">{{ questsErrorMessage }}</span>
                    <span v-else-if="securityAndCreateMpinErrorMessage2">{{
                      securityAndCreateMpinErrorMessage2
                    }}</span>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_login_or_create_btn`" depressed :color="$store.state.buttonThemeColor"
                  :loading="createOr2faLoader" :disabled="createOr2faLoader" @click="vaidateAnswer"
                  class="flat w-100 text-capitalize fsize14 white--text">{{ this.whichStep == "2FA" ? "Login" : "Create"
                  }}</v-btn>
              </form>
              <div class="
                    mt-2
                    mb-4
                    d-flex
                    justify-space-between
                    fsize12
                    letter-space
                    linkColor
                  ">
                <span @click="checkIsMpinOrPasswordForget('Unblock')" id="unblock_acc_action">Unblock Account</span>
              </div>
              <div class="d-flex justify-center fsize12">
                <span class="primaryColor" id="back_to_label">Back to</span>
                <span class="linkColor pl-1" id="back_to_action" @click="clearLocal">Login</span>
              </div>
            </div>

            <div v-if="this.whichStep == 'Forget' || this.whichStep == 'Unblock'">
              <div class="fsize20 primaryColor font-weight-regular mb-2" :id="`${this.whichStep}_label`">
                {{
                  this.whichStep == "Forget"
                  ? "Account Recovery"
                  : "Account Unblock"
                }}
              </div>
              <div class="secondColor fsize14 mb-4" :id="`${this.whichStep}_action`">
                {{
                  this.whichStep == "Forget"
                  ? "Reset your password with valid Data."
                  : "Unblock your " +
                  $store.state.projectLink +
                  " Account with vaild data."
                }}
              </div>

              <form action="" @keyup.enter="vailDateAccount">
                <div class="mb-3">
                  <label class="fsize14 primaryColor pl-2" :id="`${this.whichStep}_user_Id`">User ID</label>
                  <input :id="`${this.whichStep}_user_inp`" ref="unblock" type="text" v-model="forgetUser"
                    placeholder="Enter your User ID" class="primaryColor input-field fsize14" maxlength="50"
                    @input="forgetUser = forgetUser.toUpperCase()" autofocus autocomplete="off" />
                  <div class="error-msg">
                    <span v-if="forgetUserErrorMessage" :id="`${this.whichStep}_user_err_msg`">{{
                      forgetUserErrorMessage
                    }}</span>
                  </div>

                  <label class="fsize14 primaryColor pl-2" :id="`${this.whichStep}_pan_label`">PAN Number</label>
                  <input :id="`${this.whichStep}_pan_inp`" type="text" v-model="panNumber" maxlength="10"
                    placeholder="Enter your PAN Number" class="primaryColor input-field fsize14"
                    @input="panNumber = panNumber.toUpperCase()" autocomplete="off" />
                  <div class="error-msg" :id="`${this.whichStep}_pan_err_msg`">
                    <span v-if="panErrorMessage">{{ panErrorMessage }}</span>
                  </div>
                  <div class="negativeColor fsize12 h-12" :id="`${this.whichStep}_common_err_msg`">
                    <span v-if="
                      (this.whichStep == 'Forget' ||
                        this.whichStep == 'Unblock') &&
                      unblockErrorMessage != ''
                    ">{{ unblockErrorMessage }}</span>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_reset_unblock_btn`" depressed :color="$store.state.buttonThemeColor"
                  @click="vailDateAccount" :loading="forgetOrUnblockLoader" :disabled="forgetOrUnblockLoader"
                  class="flat w-100 text-capitalize fsize14 white--text">{{ this.whichStep == "Forget" ? "Reset" :
                    "Unblock" }}</v-btn>
              </form>

              <div class="d-flex justify-center fsize12 mt-4">
                <span class="primaryColor" id="back_to_label">Back to</span>
                <span class="linkColor pl-1" id="back_to_action" @click="clearLocal">Login</span>
              </div>
            </div>
            <div v-if="this.whichStep == 'authorize'">
              <div class="
                    fsize20
                    primaryColor
                    font-weight-regular
                    mb-2
                    text-center
                  " :id="`${this.whichStep}_vendor_name`">
                Authorize {{ $store.state.projectName }}
              </div>
              <div class="text-center fsize14 text-center" :id="`${this.whichStep}_action_tag`">
                Permission required by the app
              </div>
              <ul class="fsize14 ml-4 pt-4 primaryColor" :id="`${this.whichStep}_action_ul`">
                <li>Access holding and positions portfolio</li>
                <li>Place, modify, and cancel otders</li>
                <li>View your account balance and margins</li>
                <li>View your profile details</li>
              </ul>
              <div class="ml-4 mt-2 mb-8 d-flex align-center">
                <input type="checkbox" id="myCheck" v-model="authorizeCheckbox" class="" />
                <span class="ml-4 fsize14">I agree with</span>
                <a :id="`${this.whichStep}_terms_link`" class="ml-1 fsize14"
                  href="https://stocksandsecurities.adityabirlacapital.com/Uploads/Tools/ApplicationForms/StrategyTandC.pdf"
                  target="_blank">Terms and Conditions</a>
              </div>
              <v-btn :id="`${this.whichStep}_confirm_btn`" depressed :color="$store.state.buttonThemeColor"
                @click="vaildateAuthorize" :loading="authorizeLoader" :disabled="authorizeLoader"
                class="flat w-100 text-capitalize fsize14 white--text">Authorize</v-btn>
            </div>

            <div v-if="this.whichStep == 'reset' || this.whichStep == 'create'">
              <div class="Img-avater text-center mb-2" v-if="this.whichStep == 'create'">
                <v-avatar color="#D8ECFF" size="90" id="user_avathar"> </v-avatar>
                <div class="fsize21 mt-4" id="show_user_Id">{{ getUserId }}</div>
                <div id="switch_acc_Id" class="d-flex justify-center fsize10 letter-space linkColor" @click="ChangeUser">
                  Switch Account
                </div>
              </div>
              <div class="fsize20 primaryColor font-weight-regular mb-4" :id="`${this.whichStep}_label`">
                {{this.whichStep == 'create' ? 'Create Password' :'Reset Password'}}
              </div>

              <form action="" @keyup.enter="whichStep == 'reset' ? vailDateResetPassword() : validateCreatePassword()">
                <div class="mb-3">
                  <div v-if="this.whichStep == 'reset' ">
                    <label class="fsize12 secondColor l-height-24 pl-2" :id="`${this.whichStep}_current_pass_label`">
                    Current Password</label>
                  <div class="
                        pswborder
                        rounded
                        d-flex
                        align-center
                        justify-space-between
                        w-100
                        h-40
                      ">
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_current_pass_inp`" ref="reset" class="
                            w-100
                            border-none
                            h-40
                            outline-none
                            rounded
                            py-2
                            px-4
                            fsize14
                            primaryColor
                          " placeholder="Enter Current Password" maxlength="50" autocomplete="off" v-model="currentPass"
                        :type="isCurrentPassIcon" autofocus />
                    </span>
                    <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypeCurrentPass()">
                      <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="curentPassShowOrHide ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                        :color="$store.state.iconBlackColourCode" />
                    </span>
                  </div>
                  <div class="error-msg" :id="`${this.whichStep}_current_pass_err_msg`">
                    <span v-if="currentPassErrorMessage">{{
                      currentPassErrorMessage
                    }}</span>
                  </div>
                  </div>

                  <div>
                    <label class="fsize12 secondColor l-height-24 pl-2" :id="`${this.whichStep}_new_pass_label`">
                    New Password</label>
                  <div class="
                        pswborder
                        rounded
                        d-flex
                        align-center
                        justify-space-between
                        w-100
                        h-40
                      ">
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_new_pass_inp`" ref="create" class="
                            w-100
                            border-none
                            h-40
                            outline-none
                            rounded
                            py-2
                            px-4
                            fsize14
                            primaryColor
                          " placeholder="Enter New Password" maxlength="50" autocomplete="off" v-model="newPass"
                        :type="isNewPassIcon" />
                    </span>
                    <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypeNewPass()">
                      <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="newPassShowOrHide ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                        :color="$store.state.iconBlackColourCode" />
                    </span>
                  </div>
                  <div class="error-msg" :id="`${this.whichStep}_new_pass_err_msg`">
                    <span v-if="newPassErrorMessage">{{
                      newPassErrorMessage
                    }}</span>
                  </div>
                  </div>
                  
                  <div>
                    <label class="fsize12 secondColor l-height-24 pl-2" :id="`${this.whichStep}_confirm_pass_label`">
                    Confirm Password</label>
                  <div class="
                        pswborder
                        rounded
                        d-flex
                        align-center
                        justify-space-between
                        w-100
                        h-40
                      ">
                    <span class="inputWithImg cursor">
                      <input :id="`${this.whichStep}_confirm_pass_inp`" class="
                            w-100
                            border-none
                            h-40
                            outline-none
                            rounded
                            py-2
                            px-4
                            fsize14
                            primaryColor
                          " placeholder="Enter Confirm Password" maxlength="50" autocomplete="off" v-model="confirmPass"
                        :type="isConfirmPassIcon" :error-messages="confirmPassErrorMessage" />
                    </span>
                    <span class="rounded d-flex align-center h-40" @click="toggleFieldTextTypeConfirmPass()">
                      <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                        :name="confirmPassShowOrHide ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                        :color="$store.state.iconBlackColourCode" />
                    </span>
                  </div>
                  <div class="error-msg" :id="`${this.whichStep}_confirm_pass_err_msg`">
                    <span v-if="confirmPassErrorMessage">{{
                      confirmPassErrorMessage
                    }}</span>
                  </div>
                  </div>
                </div>
                <v-btn :id="`${this.whichStep}_btn`" depressed :color="$store.state.buttonThemeColor"
                  @click="whichStep == 'reset' ? vailDateResetPassword() : validateCreatePassword()" :loading="resetLoader" :disabled="resetLoader"
                  class="flat w-100 text-capitalize fsize14 white--text">{{this.whichStep == 'reset' ? 'Reset' : 'Create'}}</v-btn>
              </form>

              <div class="d-flex justify-center fsize12 mt-4">
                <span class="primaryColor" id="back_to_label">Back to</span>
                <span class="linkColor pl-1" id="back_to_action" @click="clearLocal">Login</span>
              </div>
            </div>
            <div v-if="this.whichStep == 'Reset 2FA'">
              <div class="
                    fsize20
                    primaryColor
                    font-weight-regular
                    mb-4
                    text-center
                  ">
                Reset 2FA
              </div>
              <div class="fsize14 mb-2 secondColor text-center">
                Please select a Any Five Questions
              </div>

              <form action="" @keyup.enter="vaildateResetQues">
                <div class="w-100 mb-4 vh-70 ofy-auto">
                  <!-- <form > -->
                  <div v-for="(item, index) in this.userQuestions" :key="index">
                    <div class="row ma-0 py-2 pr-2 align-center">
                      <label class="fsize12 secondColor cursor l-height-24">
                        <input type="checkbox" ref="qes0" v-model="item.checked" :value="item.checkedValue"
                          @click="checkBox($event, item)" class="mr-2" />
                        {{ item.ques }}</label>
                      <div class="
                            pswborder
                            rounded
                            d-flex
                            align-center
                            justify-space-between
                            w-100
                            h-40
                          ">
                        <span class="inputWithImg cursor">
                          <input :disabled="!item.checked" v-model="item.value" :type="item.passwordFieldType"
                            minlength="1" maxlength="20" class="
                                w-100
                                border-none
                                h-40
                                outline-none
                                rounded
                                py-2
                                px-4
                                fsize14
                                primaryColor
                              " required />
                        </span>
                        <span class="rounded d-flex align-center h-40" @click="toggleFieldTextType(item)">
                          <customIcon class="rounded h-40 InputWithImg cursor pt-2 pr-2"
                            :name="item.fieldTextType ? 'eyeopen' : 'eyeclose'" :width="'24'" :height="'24'"
                            :color="$store.state.iconBlackColourCode" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <!-- </form> -->
                </div>
              </form>

              <v-btn depressed :color="$store.state.buttonThemeColor" @click="vaildateResetQues" :loading="reset2faLoader"
                :disabled="reset2faLoader" class="flat w-100 text-capitalize fsize14 white--text">Save</v-btn>
            </div>
          </div>
          <div class="d-flex justify-center fsize12 letter-space" v-if="this.whichStep == 'OTP'">
            Otp is sent to your registered mobile number & Email Id
          </div>

          <div class=" text-center fsize12 letter-space mt-4">
            <div class="w-100">Member name:  <b>Aditya Birla Money Limited</b></div>
            <div class="w-100">SEBI Registration Code:  NSE/BSE/MCX/NCDEX:INZ000172636 ; NSDL /CDSL: IN-DP-17-2015</div>
            <div class="w-100">Member Code: NSE 13470, BSE 184, MCX 28370, NCDEX 00158</div>
            <div class="w-100">Registered Exchange/s name: NSE/BSE/MCX</div>
            <div class="w-100">Exchange approved segment/s: Equity, F&O, CDS, Commodities Derivatives.</div>
          </div>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import errorHandling from "../store/Services/errorHandling";
import customIcon from '../components/customIcon.vue'
import common from '../mixins/commonFunctions'
export default {
  mixins: [common],
  data: () => ({
    newPassAndMpin: "",
    passwordShow: false,
    secQuesOneAndNewMpin: "",
    secQuesTwoAndConMpin: "",
    forgetUser: "",
    panNumber: "",
    errorMessages: "",
    ssoMode: null,
    vendorName: null,
    redirectionUrl: null,
    confirmPass: "",
    confirmPassErrorMessage: "",
    newPass: "",
    newPassErrorMessage: "",
    currentPass: "",
    currentPassErrorMessage: "",
    selectedArray: [],
    counter: 0,

    // passOrMpinicon
    passwordFieldTypePassOrMpin: "password",
    passOrMpinShowOrHide: false,

    abmlPassShowOrHide: "password",
    isAbmlPassShow: false,

    // sec-q
    isSecQues1: "password",
    isSecQues2: "password",
    isSecQues1ShowOrHide: false,
    isSecQues2ShowOrHide: false,
    // reset
    isCurrentPassIcon: "password",
    curentPassShowOrHide: false,
    isNewPassIcon: "password",
    newPassShowOrHide: false,
    isConfirmPassIcon: "password",
    confirmPassShowOrHide: false,

    abmlPassword: null,
    authorizeCheckbox: false,
  }),
  components: {
    customIcon
  },

  methods: {
    toggleFieldTextTypePassOrMpin() {
      this.passOrMpinShowOrHide = !this.passOrMpinShowOrHide;
      this.passwordFieldTypePassOrMpin = this.passOrMpinShowOrHide
        ? "text"
        : "password";
    },
    toggleFieldTextTypeAbmlPass() {
      this.isAbmlPassShow = !this.isAbmlPassShow;
      this.abmlPassShowOrHide = this.isAbmlPassShow
        ? "text"
        : "password";
    },
    toggleFieldTextTypeSecQ1() {
      this.isSecQues1ShowOrHide = !this.isSecQues1ShowOrHide;
      this.isSecQues1 = this.isSecQues1ShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeSecQ2() {
      this.isSecQues2ShowOrHide = !this.isSecQues2ShowOrHide;
      this.isSecQues2 = this.isSecQues2ShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeCurrentPass() {
      this.curentPassShowOrHide = !this.curentPassShowOrHide;
      this.isCurrentPassIcon = this.curentPassShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeNewPass() {
      this.newPassShowOrHide = !this.newPassShowOrHide;
      this.isNewPassIcon = this.newPassShowOrHide ? "text" : "password";
    },
    toggleFieldTextTypeConfirmPass() {
      this.confirmPassShowOrHide = !this.confirmPassShowOrHide;
      this.isConfirmPassIcon = this.confirmPassShowOrHide ? "text" : "password";
    },
    checkQuickLogin() {
      if (!!this.abmlPassword) {
        let jsonObj = {
          userId: this.getUserId,
          userData: this.abmlPassword,
        }

        // jsonObj.loginType = "WEB"
        this.$store.dispatch("authentication/abmlLoginservice", jsonObj)
        this.resetMpin()
        this.reset2FA()
        this.resetPasswordField()
      } else {
        !!this.abmlPassword ? '' : this.$store.commit(
          "authentication/setAbmlPassErrorMessage",
          "Please Enter Your Password"
        );
      }
    },
    checkUserId() {
      if (!!this.getUserId) {
        let userfetch = {
          userId: this.getUserId,
        };
        this.$store.dispatch("authentication/getUserId", userfetch);
        this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
        this.passwordAndMpinResetField();
      } else {
        this.errorMessages = "Please Enter Your UserID";
      }
    },
    checkVaildPassOrMpin() {
      if (!!this.newPassAndMpin) {
        if (this.whichStep == "M-Pin") {
          let userfetch;
          this.$store.state.ssoQuery == ""
            ? (userfetch = {
              mpin: this.newPassAndMpin,
              userId: this.getUserId,
            })
            : (userfetch = {
              mpin: this.newPassAndMpin,
              userId: this.getUserId,
              callBackUrl: this.$store.state.ssoQuery.redirectUrl,
              loginMode: this.$store.state.ssoQuery.mode,
              vendor: this.$store.state.ssoQuery.vendor,
            });
          userfetch.loginType = "WEB"
          this.newPassAndMpin.length == 6
            ? this.$store.dispatch("authentication/callMpin", userfetch)
            : this.$store.commit(
              "authentication/setPasswordAndMpinErrorMessage",
              "Please Enter 6 Digits"
            );
        } else {
          let userfetch;
          this.$store.state.ssoQuery == ""
            ? (userfetch = {
              otp: this.newPassAndMpin,
              userId: this.getUserId,
            })
            : (userfetch = {
              otp: this.newPassAndMpin,
              userId: this.getUserId,
              callBackUrl: this.$store.state.ssoQuery.redirectUrl,
              loginMode: this.$store.state.ssoQuery.mode,
              vendor: this.$store.state.ssoQuery.vendor,
            });
          userfetch.loginType = "WEB"
          if((this.secondryStep && this.secondryStep == 'ForgetOtp' && this.newPassAndMpin.length == 4) || (this.newPassAndMpin.length == 6 && !this.secondryStep)){
            this.$store.dispatch("authentication/validateOtp", userfetch)
          }else{
            this.$store.commit(
              "authentication/setPasswordAndMpinErrorMessage",
              this.secondryStep && this.secondryStep == 'ForgetOtp' ? "OTP should be 4 Digit" : "OTP should be 6 Digit"
            );
          }
        }
      } else {
        this.$store.commit(
          "authentication/setPasswordAndMpinErrorMessage",
          this.whichStep == "M-Pin"
            ? "Please Enter your Mpin"
            : "Please Enter your 6 Digit OTP"
        );
      }
    },
    clearLocal() {
      localStorage.clear();
      this.$store.commit("authentication/setWhichStep", "userId");
      this.$store.commit("authentication/setUserId", "");
      this.newPassAndMpin = null;
      this.secQuesOneAndNewMpin = null;
      this.secQuesTwoAndConMpin = null;
      this.$nextTick(() => {
        this.$refs.user.focus();
      });
    },
    vaidateAnswer() {
      if (!!this.secQuesTwoAndConMpin && !!this.secQuesOneAndNewMpin) {
        if (this.whichStep == "2FA") {
          let mapObject;
          this.$store.state.ssoQuery == ""
            ? (mapObject = {
              answer1: this.secQuesOneAndNewMpin,
              answer2: this.secQuesTwoAndConMpin,
              userId: this.getUserId,
              sCount: this.userQuestions.length.toString(),
              sIndex:
                this.userQuestions[0]["index"] +
                "|" +
                this.userQuestions[1]["index"],
            })
            : (mapObject = {
              answer1: this.secQuesOneAndNewMpin,
              answer2: this.secQuesTwoAndConMpin,
              userId: this.getUserId,
              sCount: this.userQuestions.length.toString(),
              sIndex:
                this.userQuestions[0]["index"] +
                "|" +
                this.userQuestions[1]["index"],
              callBackUrl: this.$store.state.ssoQuery.redirectUrl,
              loginMode: this.$store.state.ssoQuery.mode,
              vendor: this.$store.state.ssoQuery.vendor,
            });
        }


        let json = {
          userId: this.getUserId,
          mpin: this.secQuesTwoAndConMpin,
        };
        this.whichStep == "createMpin" && this.secQuesOneAndNewMpin.length != 6
          ? this.$store.commit(
            "authentication/setQuesAndMpinErrorMessageOne",
            "New Mpin should be 6 Digits"
          )
          : "";
        this.whichStep == "createMpin" && this.secQuesTwoAndConMpin.length != 6
          ? this.$store.commit(
            "authentication/setQuesAndMpinErrorMessageTwo",
            "Confirm Mpin should be 6 Digits"
          )
          : "";
        this.whichStep == "createMpin" &&
          this.secQuesTwoAndConMpin.length == 6 &&
          this.secQuesOneAndNewMpin.length == 6 &&
          this.secQuesOneAndNewMpin != this.secQuesTwoAndConMpin
          ? this.$store.commit(
            "authentication/setQuesAndMpinErrorMessageTwo",
            "Confirm mpin does not match new Mpin"
          )
          : "";
        this.whichStep == "createMpin" &&
          this.secQuesTwoAndConMpin.length == 6 &&
          this.secQuesOneAndNewMpin.length == 6 &&
          this.secQuesOneAndNewMpin == this.secQuesTwoAndConMpin
          ? this.$store.dispatch("authentication/createMpin", json)
          : "";
      } else {
        !!this.secQuesOneAndNewMpin
          ? ""
          : this.$store.commit(
            "authentication/setQuesAndMpinErrorMessageOne",
            this.whichStep == "2FA"
              ? "Please Enter your Answer"
              : "New Mpin is Cannot Empty"
          );
        !!this.secQuesTwoAndConMpin
          ? ""
          : this.$store.commit(
            "authentication/setQuesAndMpinErrorMessageTwo",
            this.whichStep == "2FA"
              ? "Please Enter your Answer"
              : "Confirm Mpin is Cannot Empty"
          );
      }
    },
    checkIsMpinOrPasswordForget(key) {
      this.newPassAndMpin = null;
      this.forgetUser = null;
      this.panNumber = null;
      this.$store.commit("authentication/setForgetUserErrorMessage", "");
      this.$store.commit("authentication/setPanErrorMessage", "");
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
      if (this.whichStep == "M-Pin") {
        this.passwordAndMpinResetField();
        this.$store.commit("authentication/setWhichStep", "Password");
        this.$refs.mpin.focus();
      } else {
        this.$store.commit("authentication/setWhichStep", key);
      }
    },

    ChangeUser() {
      this.$store.commit("authentication/setWhichStep", "userId");
      this.$store.commit("authentication/setUserId", "");
      localStorage.setItem("currentUser", JSON.stringify(""));
      this.newPassAndMpin = null;
      requestAnimationFrame(() => {
        this.$refs.user.focus();
      });
      this.passwordAndMpinResetField();
    },

    keyPressNumeric(event) {
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
      this.$store.commit("authentication/setQuesAndMpinErrorMessageOne", "");
      this.$store.commit("authentication/setQuesAndMpinErrorMessageTwo", "");
      this.$store.commit("authentication/setQuestionsErrorMessage", "");
      if (
        event.keyCode != 13 &&
        (this.whichStep == "M-Pin" ||
          this.whichStep == "createMpin" ||
          this.whichStep == "Password" || this.whichStep == "OTP")
      ) {
        var inp = String.fromCharCode(event.keyCode);
        if (/[0-9]/.test(inp)) {
          return true;
        } else {
          event.preventDefault();
          return false;
        }
      }
    },

    vailDateAccount() {
      if (!!this.forgetUser && !!this.panNumber) {
        let unblock = {
          pan: this.panNumber,
          email: "",
          userId: this.forgetUser.toUpperCase(),
        };

        let forget = {
          userId: this.forgetUser.toUpperCase(),
          pan: this.panNumber
        };
        this.whichStep == "Forget"
          ? this.$store.dispatch("authentication/forgetPassword", forget)
          : this.$store.dispatch("authentication/unblockAccount", unblock);
      } else {
        !!this.forgetUser
          ? ""
          : this.$store.commit(
            "authentication/setForgetUserErrorMessage",
            "Please Enter your UserID"
          );
        !!this.panNumber
          ? ""
          : this.$store.commit(
            "authentication/setPanErrorMessage",
            "Please Enter PAN Number"
          );
      }
    },

    vaildateAuthorize() {
      if (this.authorizeCheckbox) {
        let user = {
          userId: this.getUserId,
          vendor: this.$store.state.ssoQuery.vendor,
          callBackUrl: this.$store.state.ssoQuery.redirectUrl,
        };
        this.$store.dispatch("authentication/authorizeVenfor", user);
      } else {
        errorHandling.toaster(
          "Authorize",
          "danger",
          "Please read and accept terms and conditions to proceed",
          3000
        );
      }
    },

    vailDateResetPassword() {
      if (!!this.currentPass && !!this.newPass && !!this.confirmPass) {
        var user = this.getUserId;
        let jsonObj = {
          oldPassword: this.currentPass,
          newPassword: this.confirmPass,
          preLogin: "Y",
          userId: user,
          userSessionID: this.userSessionId,
        };
        this.newPass == this.confirmPass
          ? this.$store.dispatch("authentication/resetPassword", jsonObj)
          : (this.confirmPassErrorMessage =
            "Confirm Password does not match New Password");
        this.passwordAndMpinResetField()
      } else {
        !!this.currentPass
          ? ""
          : (this.currentPassErrorMessage =
            "Please Enter your Current Password");
        !!this.newPass
          ? ""
          : (this.newPassErrorMessage = "Please Enter your New Password");
        !!this.confirmPass
          ? ""
          : (this.confirmPassErrorMessage =
            "Please Enter your Confirm Password");
      }
    },

    toggleFieldTextType(val) {
      for (let item of this.selectedArray) {
        if (val.sindex == item.sindex) {
          val.fieldTextType = !val.fieldTextType;
          val.passwordFieldType =
            val.passwordFieldType === "password" ? "text" : "password";
        }
      }
    },

    checkBox(e, val) {
      if (e.target.checked === true) {
        if (this.counter < 5) {
          val.checked = true;
          for (let item of this.userQuestions) {
            if (item.sindex == val.sindex) {
              this.selectedArray.push(item);
            }
          }
          this.counter++;
        } else {
          e.target.checked = false;
          val.checked = false;
          errorHandling.toaster(
            "",
            "danger",
            "You Already Selected Five Questions",
            3000
          );
        }
      } else if (this.counter > 0) {
        this.counter--;
        for (let item of this.selectedArray) {
          if (item.sindex == val.sindex) {
            const index = this.selectedArray.indexOf(item);
            if (index !== -1) {
              this.selectedArray.splice(index, 1);
            }
          }
        }
      }
    },

    vaildateResetQues() {
      var checkCount = 0;
      var res = "";
      for (let item of this.selectedArray) {
        item.value != "" ? (checkCount = checkCount + 1) : "";
        res += item.sindex + "|" + item.value + "|";
      }

      let json = {
        userId: this.getUserId,
        ques_Ans: res.substring(0, res.length - 1),
      };
      checkCount > 0 && checkCount < 5
        ? errorHandling.toaster(
          "",
          "danger",
          "Please Fill a selected Questions",
          3000
        )
        : "";
      checkCount == 5
        ? this.$store.dispatch("authentication/saveSecQues", json)
        : "";
    },

    passwordAndMpinResetField() {
      this.abmlPassword = null;
      this.$store.commit("authentication/setAbmlPassErrorMessage", "");
      this.$store.commit("authentication/setQuickLoginErrorMessage", "");
      this.abmlPassShowOrHide = "password";
      this.isAbmlPassShow = false;
      this.newPassAndMpin = null;
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
    },
    reset2FA() {
      this.secQuesTwoAndConMpin = null;
      this.secQuesOneAndNewMpin = null;
      this.isSecQues1ShowOrHide = false;
      this.isSecQues2ShowOrHide = false;
      (this.isSecQues1 = "password"),
        (this.isSecQues2 = "password"),
        this.$store.commit("authentication/setQuesAndMpinErrorMessageTwo", "");
      this.$store.commit("authentication/setQuesAndMpinErrorMessageOne", "");
    },
    resetPasswordField() {
      this.currentPass = null;
      this.newPass = null;
      this.confirmPass = null;
      this.curentPassShowOrHide = false;
      this.newPassShowOrHide = false;
      this.confirmPassShowOrHide = false;
      this.currentPassErrorMessage = "";
      this.newPassErrorMessage = "";
      this.newPassErrorMessage = "";
      this.isConfirmPassIcon = "password";
      this.isNewPassIcon = "password";
      this.isCurrentPassIcon = "password";
    },

    resetMpin() {
      this.newPassAndMpin = null
      this.passOrMpinShowOrHide = false
      this.passwordFieldTypePassOrMpin = "password";
      this.$store.commit("authentication/setPasswordAndMpinErrorMessage", "");
    },

    resendOtp() {
      this.newPassAndMpin = null

      if (this.secondryStep && this.secondryStep == 'ForgetOtp') {
        let forget = {
          userId: this.forgetUser.toUpperCase(),
          pan: this.panNumber
        };
        this.$store.dispatch("authentication/forgetPassword", forget)
      } else {
        let jsonObj = {
          userId: this.getUserId,
          userData: this.abmlPassword,
        }
        this.$store.dispatch('authentication/abmlLoginservice', jsonObj)
      }
    },
    validateCreatePassword(){
      if ( !!this.newPass && !!this.confirmPass) {
        var user = this.getUserId;
        let jsonObj = {
          password:this.confirmPass,
          userId: user
        };
        this.newPass == this.confirmPass
          ? this.$store.dispatch("authentication/resetPassword", jsonObj)
          : (this.confirmPassErrorMessage =
            "Confirm Password does not match New Password");
        this.passwordAndMpinResetField()
      } else {
        !!this.newPass
          ? ""
          : (this.newPassErrorMessage = "Please Enter your New Password");
        !!this.confirmPass
          ? ""
          : (this.confirmPassErrorMessage =
            "Please Enter your Confirm Password");
      }
    }
  },

  watch: {
    currentPass: function () {
      this.currentPassErrorMessage = "";
    },
    newPass: function () {
      this.newPassErrorMessage = "";
    },
    confirmPass: function () {
      this.confirmPassErrorMessage = "";
    },
    passwordAndMpinErrorMessage: function () {
      this.passwordAndMpinErrorMessage != "" ? (this.newPassAndMpin = "") : "";
    },
    forgetUserErrorMessage: function () {
      this.forgetUserErrorMessage != "" ? (this.forgetUser = "") : "";
    },
    panErrorMessage: function () {
      this.panErrorMessage != "" ? (this.panNumber = "") : "";
    },
    panNumber: function (value) {
      value ? this.$store.commit("authentication/setPanErrorMessage", "") : ''
      value ? this.$store.commit("authentication/setUnblockErrorMessage", "") : ''
    },
    forgetUser: function (value) {
      value ? this.$store.commit("authentication/setForgetUserErrorMessage", "") : ''
      value ? this.$store.commit("authentication/setUnblockErrorMessage", "") : ''
    },
    securityAndCreateMpinErrorMessage1: function () {
      this.securityAndCreateMpinErrorMessage1 != ""
        ? (this.secQuesOneAndNewMpin = "")
        : "";
    },
    securityAndCreateMpinErrorMessage2: function () {
      this.securityAndCreateMpinErrorMessage2 != ""
        ? (this.secQuesTwoAndConMpin = "")
        : "";
    },
    unblockErrorMessage: function () {
      this.unblockErrorMessage != "" ? (this.forgetUser = "") : "";
      this.unblockErrorMessage != "" ? (this.panNumber = "") : "";
    },
    questsErrorMessage: function () {
      this.questsErrorMessage != "" ? (this.secQuesOneAndNewMpin = "") : "";
      this.questsErrorMessage != "" ? (this.secQuesTwoAndConMpin = "") : "";
    },
    abmlPassword: function (value) {
      value != null
        ? this.$store.commit("authentication/setQuickLoginErrorMessage", "")
        : "";
      value
        ? this.$store.commit("authentication/setAbmlPassErrorMessage", "")
        : "";
    },
    quickLoginErrorMessage: function () {
      this.quickLoginErrorMessage != "" ? (this.abmlPassword = null) : "";
    },
  },
  computed: {
    userId: {
      get: function () {
        return this.$store.state.userId;
      },
      set: function (newValue) {
        this.$store.commit("authentication/setUserId", newValue.toUpperCase());
      },
    },
    whichStep: {
      get: function () {
        if (this.$store.state.authentication.whichStep == '"userId"') {
          this.$nextTick(() => this.$refs.user.focus());
        } else if (this.$store.state.authentication.whichStep == "Password") {
          this.$nextTick(() => this.$refs.abmlPass.focus());
        } else if (this.$store.state.authentication.whichStep == "M-Pin" || this.$store.state.authentication.whichStep == "OTP") {
          this.$nextTick(() => this.$refs.mpin.focus());
        } else if (
          this.$store.state.authentication.whichStep == "2FA" ||
          this.$store.state.authentication.whichStep == "createMpin"
        ) {
          this.$nextTick(() => this.$refs.twofa.focus());
        } else if (this.$store.state.authentication.whichStep == "reset" || this.$store.state.authentication.whichStep == "create") {
          this.resetPasswordField()
          this.$store.state.authentication.whichStep == "reset" ? this.$nextTick(() => this.$refs.reset.focus()) : this.$nextTick(() => this.$refs.create.focus())
        } else if (
          this.$store.state.authentication.whichStep == "Unblock" ||
          this.$store.state.authentication.whichStep == "Forget"
        ) {
          this.$nextTick(() => this.$refs.unblock.focus());
        } else if (this.$store.state.authentication.whichStep == "authorize") {
          this.authorizeCheckbox = false;
        }

        return this.$store.state.authentication.whichStep;
      },
      set: function (value) { },
    },
    ...mapState("authentication", [
      "userQuestions",
      "passwordAndMpinErrorMessage",
      "userSessionId",
      "forgetUserErrorMessage",
      "securityAndCreateMpinErrorMessage1",
      "securityAndCreateMpinErrorMessage2",
      "panErrorMessage",
      "questsErrorMessage",
      "unblockErrorMessage",
      "userIdLoader",
      "passOrMpinLoader",
      "createOr2faLoader",
      "forgetOrUnblockLoader",
      "authorizeLoader",
      "resetLoader",
      "reset2faLoader",
      "abmlPassErrorMessage",
      "quickLoader",
      "quickLoginErrorMessage",
      "getTimer",
      "secondryStep"
    ]),
    ...mapGetters("authentication", [
      "getUserId",
      "getUserSession",
      "getUserSessionDto",
    ]),
  },
  mounted() {
    var checkStep = JSON.parse(localStorage.getItem("whichStep"));
    !!checkStep && checkStep != "undefined"
      ? this.$store.commit("authentication/setWhichStep", checkStep)
      : "";
    var checkQues = JSON.parse(localStorage.getItem("secQuestion"));
    !!checkQues && checkQues != "undefined"
      ? this.$store.commit("authentication/setSecQuestionRefresh", checkQues)
      : "";
    var checkIsMpin = JSON.parse(localStorage.getItem("isMpin"));
    checkIsMpin != undefined && checkIsMpin != "undefined"
      ? this.$store.commit("authentication/setIsMpin", checkIsMpin)
      : "";
    var checkAuthcode = JSON.parse(localStorage.getItem("authcode"));
    !!checkAuthcode && checkAuthcode != "undefined"
      ? this.$store.commit("authentication/setRedirectUrl", checkAuthcode)
      : "";
    this.selectedArray = [];
  },
};
</script>

<style>
.login-overflow {
  overflow-y: auto !important;
}
</style>